import React from 'react'

function Footer() {
  return (
    <div className="flex h-full items-center justify-center">
      <p className="m-0">© {new Date().getFullYear()} Whitefriar.com. All Rights Reserved.</p>
    </div>
  )
}

export default Footer
